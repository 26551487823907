#search-wrapper {
    --background: black;
    --width: 400px;

    background: var(--background);
    border-radius: 8px 8px 0 0;
    display: none;
    height: fit-content;
    left: 50%;
    padding: 30px 10px 10px 10px;
    position: fixed;
    top: 25%;
    transform: translate(-50%, -50%);
    z-index: 1001;

    i {
        opacity: 0.5;
    }

    &::before {
        background: rgba(0, 0, 0, 0.5);
        content: "";
        height: 100dvh;
        left: -50%;
        position: fixed;
        top: -50%;
        transform: scale(10000%);
        width: 100dvw;
    }

    #search-bar {
        align-items: center;
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        font-size: 1.2rem;
        justify-content: space-between;
        padding-left: 10px;
        width: var(--width);

        &::before {
            content: "Search";
            font-size: 0.8rem;
            opacity: 0.5;
            position: absolute;
            text-transform: uppercase;
            top: 10px;
            transform: translateX(42px);
        }

        i.fas,
        i.fa-solid {
            font-size: 20px;
            min-width: 30px;
            text-align: center;
        }

        p {
            font-size: 0.95rem;
            padding: 0 10px;
            white-space: nowrap;
        }

        input {
            background: transparent;
            border: none;
            color: #fff;
            font-size: 1.05rem;
            height: 100%;
            outline: none;
            padding: 0 10px 0 0;
            width: 100%;
        }

        &[data-selected="true"] {
            border-color: #fff;
        }

        #search-results {
            background: var(--background);
            border-radius: inherit;
            display: flex;
            flex-direction: column;
            gap: 10px;
            left: 0;
            margin-top: 10px;
            padding: 10px;
            position: absolute;
            top: 68px;
            width: calc(var(--width) + 20px);

            .search-result {
                background: rgba(255, 255, 255, 0.1);
                border-radius: inherit;
                display: flex;
                flex-direction: row;
                padding: 12px 0 12px 12px;

                p {
                    margin: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &[data-selected="true"],
                &:hover {
                    background: rgba(255, 255, 255, 0.2);
                }

                i.action {
                    font-size: 0.9rem;
                    margin-left: 8px;
                    text-transform: capitalize;
                }
            }
        }
    }
}