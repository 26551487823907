.Dashboard main .urls-content section[data-loading="true"] .loading-row {
    height: 40px;
    margin-bottom: 2px;
    border-radius: var(--border-radius);
}

.Dashboard main .urls-content section[data-loading="true"] .loading-row-1 {
    animation: loadRow 1.5s linear infinite;
}

.Dashboard main .urls-content section[data-loading="true"] .loading-row-2 {
    animation: loadRow 1.5s linear infinite;
    animation-delay: 0.5s;
}

.Dashboard main .urls-content section[data-loading="true"] .loading-row-3 {
    animation: loadRow 1.5s linear infinite;
    animation-delay: 1s;
}

@keyframes loadRow {
    0% {
        background-color: var(--color-background);
    }
    50% {
        background-color: var(--color-secondary);
    }
    100% {
        background-color: var(--color-background);
    }
}

.urls-content table {
    width: 100%;
    border-collapse: collapse;
}

.urls-content table tr {
    height: 40px;
    background-color: var(--color-secondary);
}

.urls-content table tr td:first-child {
    width: 0.1%;
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.urls-content table tr td:last-child {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.urls-content table thead tr,
.urls-content table tr:nth-child(2n) {
    background-color: transparent;
}

.storage-content table tr > *,
.urls-content table tr > * {
    padding: 0 var(--spacing-primary);
    text-align: center;
}

.storage-content table tr > td:nth-child(2),
.urls-content table tr > *:first-child {
    overflow: hidden;
    width: 40%;
    max-width: 0;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.popup {
    position: absolute;
    z-index: 100000;
    top: 50px;
    left: 50%;
    display: none;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    margin: calc(var(--spacing-primary) * 3) auto;
    padding: calc(var(--spacing-primary) * 2.5);
    transform: translateX(-50%);
    text-align: center;
    border-radius: var(--border-radius);
    background-color: var(--color-secondary);
    gap: var(--spacing-primary);
}

.popup > * {
    width: 100%;
}

.popup i {
    font-size: 24px;
    color: var(--color-accent) !important;
}

#upload-file-popup progress {
    border: 0;
    border-radius: var(--border-radius);
}

.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.storage-content #selection-editor {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 var(--spacing-primary);
    transition: all 0.2s;
    gap: var(--spacing-primary);
}

.storage-content #selection-editor * {
    transition: all 0.2s;
}

.storage-content #selection-editor > * {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    padding: 0 var(--spacing-primary);
    border-radius: var(--border-radius);
    background-color: var(--color-accent);
}

.storage-content #selection-editor > i {
    width: 40px;
}

.storage-content #selection-editor i {
    color: var(--color-text);
}

.storage-content #selection-editor > *:hover i,
.storage-content #selection-editor input[type="text"]:focus i {
    padding-bottom: 4px;
    border-bottom: 2px solid var(--color-text);
}

.storage-content #selection-editor input[type="text"] {
    font-size: var(--font-size-primary);
    width: 0;
    border: none;
    outline: none;
    background-color: transparent;
}

.storage-content #selection-editor input[type="text"]:focus {
    width: 80px;
    border-bottom: 2px solid var(--color-text);
}

/* Improve! */

.storage-content #selection-editor > .spacer {
    display: none;
}

/*

Nothing to see here!

*/


.storage-content table {
    width: 100%;
    border-collapse: collapse;
}

.storage-content table tr {
    height: 40px;
    transition: all 0.2s;
    background-color: var(--color-secondary);
}

.storage-content table tr td:first-child {
    width: 0.1%;
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.storage-content table tr td:last-child {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.storage-content table thead tr,
.storage-content table tr:nth-child(2n) {
    background-color: transparent;
}

.storage-content table tr.element-active {
    border-bottom-color: var(--color-accent);
    background-color: var(--color-accent);
}

.storage-content table tr > *:first-child {
    min-width: 0;
    padding-right: 0;
}