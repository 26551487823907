form.form {
    --form-element-height: 40px;

    width: 100%;

    .form-input {
        --top-element-padding: 8px;
        height: var(--form-element-height);
        margin-block: 10px;

        position: relative;
        $top-element-width: str-length(attr(data-placeholder)) * 5.5px;
        --icon-width: 40px;

        @mixin border {
            position: absolute;
            height: 100%;
            pointer-events: none;
            transition: all 0.2s ease;
        }

        .border-left {
            @include border;
            border-radius: var(--border-radius) 0;
            border-top: 1px solid var(--color-secondary);
            left: 0;
            width: calc(var(--icon-width) + ($top-element-width / 2));
        }

        .border-right {
            @include border;
            border-radius: 0 var(--border-radius);
            border-top: 1px solid var(--color-secondary);
            right: 0;
            width: calc(100% - ($top-element-width / 2) - var(--icon-width));
        }

        .border-bottom {
            @include border;
            border: 1px solid var(--color-secondary);
            border-radius: var(--border-radius);
            border-top: none;
            width: 100%;
        }

        &::after {
            color: var(--color-text);
            content: attr(data-placeholder);
            left: var(--icon-width);
            opacity: 0.5;
            padding: 0 2px;
            pointer-events: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.2s ease;
            width: $top-element-width;
        }

        &:has(input:focus) {
            .border-left,
            .border-right,
            .border-bottom {
                border-color: var(--color-accent);
            }

            .border-left {
                width: calc(var(--icon-width) - var(--top-element-padding));
            }

            .border-right {
                width: calc(100% - $top-element-width - var(--icon-width) - var(--top-element-padding));
            }

            &::after {
                font-size: 12px;
                opacity: 1;
                top: 0;
            }
        }

        input {
            background: transparent;
            border: none;
            border-radius: var(--border-radius);
            color: var(--color-text);
            outline: none;
            padding: 12px 12px 12px calc(var(--icon-width));
            width: 100%;

            &:focus {
                border-color: var(--color-accent);
            }
        }

        i {
            font-size: 18px;
            left: 12px;
            pointer-events: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .form-segment {
        height: var(--form-element-height);

        .buttons {
            display: flex;
            flex-direction: row;
            height: 100%;
            width: 100%;

            & > * {
                border-radius: 0;
            }

            & > :first-child {
                align-items: center;
                border: 1px solid var(--color-secondary);
                border-radius: var(--border-radius) 0 0 var(--border-radius);
                display: flex;
                flex-direction: row;
                gap: 6px;
                justify-content: center;
                padding-inline: 12px 12px;

                i {
                    font-size: 18px;
                }
            }

            & > :last-child {
                border-radius: 0 var(--border-radius) var(--border-radius) 0;
            }

            button {
                background: transparent;
                border: 1px solid var(--color-secondary);
                border-inline-width: 0.5px;
                color: var(--color-text);
                cursor: pointer;
                flex: 1;
                outline: none;
                transition: all 0.2s ease;

                &:hover {
                    background: var(--color-secondary);
                    border-color: transparent;
                }

                &:focus,
                &.button-active {
                    border-color: var(--color-accent);
                }

                &.button-active {
                    background: var(--color-accent);
                    font-weight: bold;

                    i {
                        color: var(--color-text);
                    }
                }
            }

            button:last-of-type {
                border-right-width: 1px;
            }
        }
    }

    .form-section {
        .form-section-title {
            font-size: 12px;
            font-weight: 500;
            margin-block: 4px 8px;
            opacity: 0.5;
            padding: 0 12px;
        }
    }

    .form-button {
        align-items: center;
        background: var(--color-secondary);
        border: none;
        border-radius: var(--border-radius);
        color: var(--color-text);
        cursor: pointer;
        display: inline-flex;
        font-weight: bold;
        height: var(--form-element-height);
        justify-content: center;
        transition: background 0.2s ease;
        width: 100%;

        i {
            font-size: 18px;
            margin-right: 8px;
        }

        &:hover {
            background: var(--color-accent);
        }
    }

    .form-spacer {
        font-size: 12px;
        min-height: 10px;
        opacity: 0.5;
        width: 100%;
    }
}