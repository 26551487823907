.Dashboard {
    header {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        max-width: var(--max-width);
        height: var(--header-height);
        margin: auto;

        &::before {
            position: absolute;
            width: 100vw;
            height: 100%;
            content: "";
            background-color: var(--color-secondary);
        }

        #title {
            padding: 0 var(--spacing-primary);
        }

        #spacer {
            width: 100%;
        }

        #signout-button {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            height: inherit;
            padding: 0 var(--spacing-primary);
            transition: background-color 0.2s;
            gap: var(--spacing-primary);

            &:hover {
                background-color: black;
            }

            img {
                width: 34px;
                height: 34px;
                border-radius: 50%;
                object-fit: cover;
            }

            p {
                display: flex;
                flex-direction: column;
                text-align: left;

                #name {
                    font-size: var(--font-size-primary);
                    font-weight: bold;
                }

                #email {
                    font-size: calc(var(--font-size-primary) - 2px);
                }
            }
        }
    }

    main {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: var(--max-width);
        min-height: calc(100dvh - var(--header-height));
        margin: 0 auto;

        .connection-error {
            position: absolute;
            top: calc(var(--header-height) + var(--spacing-primary));
            padding: var(--spacing-primary);
            border-radius: var(--border-radius);
            background-color: red;

            * {
                color: white;
            }
        }

        nav {
            display: flex;
            overflow-x: hidden;
            flex-direction: column;
            width: var(--nav-width);
            min-width: var(--nav-width);
            padding: var(--spacing-primary);

            #spacer {
                height: 100%;
            }

            .nav-item {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: flex-start;
                margin: 4px 0;
                transition: background-color 0.2s;
                text-decoration: none;
                color: var(--color-text);

                > .row {
                    position: relative;
                    width: 100%;
                    transition: all 0.5s ease;
                    border-radius: var(--border-radius);
                    background-color: transparent;
                }

                .fa-solid {
                    font-size: calc(var(--font-size-primary) + 4px);
                    color: var(--color-accent);
                }

                &.nav-item-active:not(:has(.nav-item-active)) > .row,
                &:hover:not(:has(.nav-item:hover)) > .row {
                    background-color: var(--color-secondary);
                }

                > .row .fa-chevron-down {
                    font-size: var(--font-size-primary);
                    position: absolute;
                    right: var(--spacing-primary);
                    transition: all 0.2s ease;
                    transform: rotateZ(-90deg);
                    opacity: 0.5;
                    color: var(--color-text);
                }

                &.nav-item-active > .row .fa-chevron-down {
                    transform: rotateZ(0deg);
                }

                .submenu {
                    display: none;
                    overflow: hidden;
                    flex-direction: row;
                    width: calc(100% - var(--spacing-primary));
                    transform: translate(var(--spacing-primary), 4px);
                }

                &.nav-item-active .submenu {
                    display: flex;
                    flex-direction: column;
                }
            }

            #storage-wrapper {
                font-size: calc(var(--font-size-primary) - 2px);
                display: flex;
                flex-direction: column;
                width: 100%;
                text-align: center;
                gap: 2px;

                #storage {
                    width: 100%;
                    height: 4px;
                    border-radius: 2px;
                    background-color: var(--color-text);

                    #storage-inner {
                        width: 0;
                        height: 4px;
                        border-radius: 2px;
                        background-color: var(--color-accent);
                    }
                }
            }

            > .nav-item:last-child {
                transition: none;
                opacity: 0.5;
            }
        }

        .content {
            width: 100%;
            margin-right: var(--spacing-primary);

            section[data-styled] {
                padding: var(--spacing-primary);
                border-radius: var(--border-radius);
                background: var(--color-secondary);
            }
        }
    }
}