:root {
    --accent: linear-gradient(to right, #a72668, #3e15a7);

    --color-accent: #6b52a7;
    --color-background: black;
    --color-text: white;
    --color-secondary: rgba(255, 255, 255, 0.075);

    --spacing-primary: 16px;

    --border-radius: 10px;

    --font-size-primary: 14px;

    --max-width: 1200px;
    --header-height: 60px;
    --nav-width: 250px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: var(--font-size-primary);
    margin: 0;
    padding: 0;
    color: var(--color-text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    background: var(--accent);
}

body {
    min-height: 100dvh;
    background: rgba(0, 0, 0, 0.8);
}

.accent-font {
    background: var(--accent);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0 0 10px #49225e);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fa-solid {
    color: var(--color-accent);
}

.accent-bar {
    width: 60px;
    height: 6px;
    border-radius: 2px;
    background-color: var(--color-accent);
}

.label {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    padding: var(--spacing-primary);
    gap: var(--spacing-primary);
}

.label i {
    width: 16px;
    text-align: center;
}

.hover-underline {
    cursor: pointer;
}

.hover-underline:hover {
    text-decoration: underline;
}

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    padding: calc(var(--spacing-primary) * 2.5);
    transform: translate(-50%, calc(-50% - var(--spacing-primary) * 5));
    border-radius: var(--border-radius);
    background-color: var(--color-secondary);

    > .label {
        padding-bottom: 0;
    }

    > .fa-solid {
        font-size: 36px;
        margin-bottom: 12px;
    }

    > p {
        margin-bottom: 18px;
        transform: translateY(-12px);
    }

    &[data-hidden="true"] {
        display: none;
    }
}

.checkbox {
    width: 12px;
    height: 12px;
    transition: background-color 0.2s;
    border: 2px solid var(--color-text);
    border-radius: 4px;

    &.checkbox-active {
        background-color: var(--color-text);
    }
}

@mixin stack($direction) {
    display: flex;
    align-items: center;
    flex-direction: $direction;
    justify-content: flex-start;
    width: 100%;
    gap: var(--spacing-primary);
}

.h-stack {
    @include stack(row);
}

.v-stack {
    @include stack(column);
}

.subtitle {
    font-family: monospace;
    font-size: 12px;
    line-height: 12px;
    display: block;
    padding-top: 4px;
    text-transform: uppercase;
    opacity: 0.8;
}